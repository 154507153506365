<template>
  <div class="box">
    <!-- <top-bar :title='this.$route.query.title' :left="true"></top-bar> -->
    <div class="workGoal">
      <div
        style="
          height: 40px;
          display: flex;
          align-items: center;
          background-color: white;
        "
      >
        <div
          style="
            height: 16px;
            width: 3px;
            background-color: #387df4;
            margin-left: 20px;
          "
        ></div>
        <div
          style="
            font-size: 18px;
            color: black;
            font-weight: 600;
            margin-left: 5px;
          "
        >
          添加{{ this.$route.query.title }}
        </div>
      </div>
      <van-form
        @submit="onSubmit"
        input-align="right"
        error-message-align="right"
      >
        <van-field
          required
          :label="addLabel"
          type="text"
          style="border: 0"
          v-model="workInfo.title"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写任务标题' }]"
        />
        <van-field
          label="所属类别"
          type="text"
          style="border: 0"
          v-model="title"
          placeholder="请输入"
          disabled
        />
        <van-field
          required
          readonly
          label="是否在驾驶舱显示"
          type="text"
          style="border: 0"
          v-model="workInfo.driveStatus"
          placeholder="请选择"
          @click="cockShow = true"
          :rules="[{ required: true, message: '请选择完成状态' }]"
        />
        <van-field
          v-model="workInfo.openDesc"
          rows="1"
          autosize
          label="任务详情描述"
          type="textarea"
          placeholder="请输入留言"
        />
        <div style="display: flex; margin-top: 30px" class="uploadBox">
          <van-uploader v-model="imageList" :after-read="afterRead" @click-preview="pdfShow" accept=".jpg,.gif,.png,.jpeg,.txt,.pdf">
            <!-- <img
              style="width: 100px; height: 100px"
              src="@/assets/img/picture.png"
              alt=""
            /> -->
          </van-uploader>
          <!-- <van-uploader
            accept="video/*"
            v-model="videoList"
            :after-read="afterRead"
          >
            <img
              style="width: 100px; height: 100px"
              src="@/assets/img/video.png"
              alt=""
            />
          </van-uploader> -->
        </div>
        <div class="btns">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <van-popup v-model="cockShow" position="bottom" >
      <van-picker
        title="请选择"
        show-toolbar
        :columns="visibleStatus"
        value-key="label"
        @confirm="cockConfirm"
        @cancel="cockShow = false"
      />
    </van-popup>
    <div v-if="pdfFlag" class="pdfBox">
      <pdf-view :pdf-url="pdfurl" @pdfClose="pdfClose"/>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import { setTimeout } from "timers";
import pdfView from "@/components/pdfView/pdfView";

export default {
  components: {
    topBar,
    pdfView
  },
  data() {
    return {
      cockShow: false,
      typeShow: false,
      imageList: [],
      videoList: [],
      title: "",
      addLabel: "",

      visibleStatus: [
        { label: "显示", value: "0" },
        { label: "不显示", value: "1" },
      ],
      workInfo: {
        title: "",
        type: "",
        driveStatus: "",
        openDesc: "",
        newFileMessages: [],
      },
      pdfFlag: false,
      pdfurl: ''
    };
  },
  mounted() {
    this.workInfo.type = this.$route.query.type;
    this.title = this.$route.query.title;
    this.addLabel = this.$route.query.title.slice(0, 2) + "标题";
  },
  methods: {
    pdfClose () {
      this.pdfFlag = false
    },
    pdfShow (file) {
      this.workInfo.newFileMessages.forEach(item => {
        if (item.oldFileName == file.file.name.split('.pdf')[0]) {
          this.pdfurl = item.objectUrl
        }
      })
      this.pdfFlag = true
    },
    cockConfirm(value) {
      this.workInfo.driveStatus = value.label;
      this.cockShow = false;
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.uploadFile(file);
    },
    uploadFile(params) {
      var formData = new FormData();
      formData.append("file", params.file);
      formData.append("path", "files/jq/open/temporary");
      this.$http({
        url: this.$http.adornUrl(`/wxapp/file/upload`),
        method: "post",
        data: formData,
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.workInfo.newFileMessages.push(data.fileMessage);
          this.$notify({ type: "success", message: "上传成功" });
        } else {
          this.$notify({ type: "danger", message: "上传失败" });
        }
      });
    },

    onSubmit() {
      let workInfo = this.workInfo
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appjqaffairsopen/save"),
        method: "post",
        data: this.$http.adornData({
          driveStatus: workInfo.driveStatus == "显示" ? 0 : 1,
          newFileMessages: workInfo.newFileMessages,
          openDesc: workInfo.openDesc,
          type: workInfo.type,
          title: workInfo.title,
        }),
      }).then(({ data }) => {
        if (data.code == 0) {
          // 加载状态结
          this.$notify({ type: "success", message: "提交成功" });
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        } else {
          this.$notify({ type: "danger", message: "提交失败" });
        }
      });
    },
  },
};
</script>

<style scope>
.notice {
  width: 100vw;
  height: 100vh;
  background-color: #f6f5f3;
  overflow: hidden;
}
.workGoal {
  width: 100vw;
  height: 100%;
  background-color: white;
  /* margin-top: 130px; */
}
.pdfBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
</style>
